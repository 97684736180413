import React, { useEffect, useState } from "react";
import { createApi } from "unsplash-js";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import { instance } from "common/Instance";
import { MdOutlineClear } from "react-icons/md";
import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import upload from "../../../../assets/img/upload.png";
import imageCompression from "browser-image-compression";

import {
  SET_ADD_PHOTOS,
  REMOVE_PHOTO,
} from "../../../../redux/reducerSlice/worldTrackSlice";
import { loadTexture } from "./EditorUtils";

const UnsplashImages = ({ open, handleClose }) => {
  const [Galleryimages, setGalleryImages] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [photos, setPhotos] = useState([]);
  const [tab, setTab] = useState("upload");
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.WorldTrack.data);
  const dispatch = useDispatch();
  const selectedPhotos = useSelector((state) => state?.WorldTrack?.data?.photos);

  useEffect(() => {
    setTab("upload");
  }, [open]);

  useEffect(() => {
    if (open) {
      const unsplash = createApi({
        accessKey: "WR48RoHswpUw4jtiBdmqfx0ifzw587K9rcyxMSvz9vM",
      });

      const getUnsplashPhotos = async () => {
        try {
          const response = await unsplash.photos.list({ page: 1, perPage: 20 });
          if (response.errors) {
            console.error("Error fetching Unsplash photos:", response.errors);
            return;
          }

          console.log(response.response, "response");

          setPhotos(response.response.results);
        } catch (error) {
          console.error("Error fetching Unsplash photos:", error);
        }
      };

      getUnsplashPhotos();
    }
  }, [open]);

  const Gallery = async () => {
    try {
      const response = await instance.get(
        `api/ar/fetchUserGallery/image`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        setGalleryImages(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
    }
  };

  useEffect(() => {
    Gallery();
  }, [open]);
  const handleSearch = async (event) => {
    const unsplash = createApi({
      accessKey: "WR48RoHswpUw4jtiBdmqfx0ifzw587K9rcyxMSvz9vM",
    });

    try {
      const response = await unsplash.search.getPhotos({
        query: event.target.value,
        page: 1,
        perPage: 10,
      });

      if (response.errors) {
        console.error("Error searching Unsplash photos:", response.errors);
        return;
      }

      setPhotos(response.response.results);
    } catch (error) {
      console.error("Error searching Unsplash photos:", error);
    }
  };

  const handleImageClick = async (photo, index) => {
    const isSelected = selectedPhotos.some(
      (selectedPhoto) => selectedPhoto.src === photo.urls.small
    );
    if (isSelected) {
      dispatch(
        REMOVE_PHOTO({
          src: photo.urls.small,
        })
      );
    } else {
      const texture = await loadTexture(photo.urls.small);
      const textureWidth = texture.image.width;
      const textureHeight = texture.image.height;
      const aspectRatio = textureWidth / textureHeight;
      const baseSise = 0.8;
      dispatch(
        SET_ADD_PHOTOS({
          id: uuidv4(),
          type: "photo",
          src: photo.urls.small,
          position: { x: 0, y: 0.0005, z: 0 },
          rotation: { x: -Math.PI / 2, y: 0, z: 0 },
          scale: { x: aspectRatio * baseSise, y: baseSise, z: baseSise },
          geometry: "Plane",
          urlLink: "",
          isAnimation: false,
          animation: {
            duration: 0,
            delay: 0,
            direction: "topToBottom",
          },
        })
      );

      handleClose();
    }
  };

  const handleImageClick1 = async (src, index) => {
    const source = src + "?not-from-cache-please";
    const texture = await loadTexture(source);
    const textureWidth = texture.image.width;
    const textureHeight = texture.image.height;
    const aspectRatio = textureWidth / textureHeight;
    const baseSise = 0.8;
    dispatch(
      SET_ADD_PHOTOS({
        id: uuidv4(),
        type: "photo",
        src: src,
        position: { x: 0, y: 0.0005, z: 0 },
        rotation: { x: -Math.PI / 2, y: 0, z: 0 },
        scale: { x: aspectRatio * baseSise, y: baseSise, z: baseSise },
        geometry: "Plane",
        urlLink: "",
        isAnimation: false,
        animation: {
          duration: 0,
          delay: 0,
          direction: "topToBottom",
        },
      })
    );

    handleClose();
  };

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;

      if (files && files.length > 0) {
        const imageFile = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, or .png file");
          return;
        }

        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 5) {
          setLoading(false);
          toast.error("File size should be less than or equal to 5 MB");
          return;
        }

        const formData = new FormData();
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          const compressedBlob = await imageCompression(imageFile, options);
          console.log("Compressed file:", compressedBlob);
          console.log(
            `Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`
          );

          // Convert Blob to File
          const compressedFile = new File([compressedBlob], imageFile.name, {
            type: imageFile.type,
            lastModified: Date.now(),
          });

          formData.append("file", compressedFile);
          formData.append("assetType", "image");
          const fileName = compressedFile.name
            .split(".")
            .slice(0, -1)
            .join(".");
          console.log("File name:", fileName);

          const response = await instance.post(
            `/api/WTracking/upload/${user.username}/${arData.name}`,
            formData,
            {
              headers: {
                authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 200) {
            const texture = await loadTexture(response.data.url);
            const textureWidth = texture.image.width;
            const textureHeight = texture.image.height;
            const aspectRatio = textureWidth / textureHeight;
            const baseSize = 4;

            dispatch(
              SET_ADD_PHOTOS({
                id: uuidv4(),
                type: "photo",
                name: fileName,
                src: response.data.url,
                position: { x: 0, y: 0.0005, z: 0 },
                rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                scale: { x: aspectRatio * baseSize, y: baseSize, z: baseSize },
                isAnimation: false,
                geometry: "Plane",
                defaultImage:
                  "https://image.shutterstock.com/image-photo/natural-stone-concrete-podium-green-260nw-2156212789.jpg",
                animation: {
                  duration: 0,
                  delay: 0,
                  direction: "topToBottom",
                },
              })
            );

            toast.success("Image is uploaded successfully");
            handleClose();
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (compressionError) {
          console.error("Compression error:", compressionError);
          toast.error("Image compression failed");
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("Image is not uploaded");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div className="relative ">
          <div className="relative mb-9 mt-0   ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold">Images</h4>
              <span className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></span>
            </div>
            <div className="mb-2 flex w-full items-center justify-between gap-1 px-2 ">
              <button
                className={`w-full rounded-lg  ${
                  tab === "upload"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }  px-2 py-3  `}
                onClick={() => setTab("upload")}
              >
                Upload Image
              </button>
              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "library"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("library")}
              >
                Image Library
              </button>

              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "browseonline"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("browseonline")}
              >
                Browse online
              </button>
            </div>

            {tab === "browseonline" ? (
              <>
                <div className="flex items-center justify-center gap-2 px-2 py-2">
                  <input
                    type="text"
                    placeholder="Search for Image"
                    className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm shadow-2xl outline-none"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e);
                    }}
                  />
                </div>
                <div className="grid max-h-[100vh] grid-cols-1 gap-2 overflow-y-auto px-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                  {photos?.map((photo, index) => (
                    <div
                      onClick={() => handleImageClick(photo, index)}
                      key={photo.id}
                      className={`cursor-pointer overflow-hidden rounded-lg shadow-lg ${
                        selectedPhotos.some(
                          (selectedPhoto) =>
                            selectedPhoto.src === photo.urls.small
                        )
                          ? "border-4 border-green-500"
                          : "border-4 border-gray-200"
                      }`}
                    >
                      <img
                        src={photo.urls.small}
                        alt={photo.description}
                        className="h-48 w-full object-cover"
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : tab === "library" ? (
              Galleryimages?.length > 0 ? (
                <div className="grid max-h-[100vh] grid-cols-1 gap-2 overflow-y-auto p-2 px-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                  {Galleryimages?.map((element, index) => (
                    <div
                      className="overflow-hidden rounded-lg bg-gray-50 shadow-lg"
                      key={index}
                    >
                      {console.log(element, "1element")}

                      <img
                        src={element}
                        alt="Photo_"
                        className="h-48 w-full cursor-pointer object-cover "
                        onClick={() => handleImageClick1(element)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-40 flex flex-col items-center justify-center gap-4 text-center">
                  <p className="text-md font-semibold ">
                    The Images you will upload will be shown here
                  </p>
                </div>
              )
            ) : (
              <div>
                <div className=" mb-4 mt-10 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                  <img
                    src={upload}
                    alt="select "
                    className="h-[40%] w-[40%] rounded-lg"
                  />

                  <h1 className="py-2 text-center text-2xl font-semibold text-gray-800">
                    Upload Image
                  </h1>
                </div>

                <div className="px-4">
                  <label
                    htmlFor="fileInput"
                    className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/png, image/jpeg, image/jpg"
                      className="hidden "
                      onChange={handleFileUpload}
                    />

                    <div className="flex flex-col items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                        className=" mb-2 mt-2 cursor-pointer rounded-md bg-[#3d2fa9] px-4 py-2 text-center text-xl text-white"
                      >
                        Browse
                      </button>
                      <p className="mt-4 text-lg font-semibold text-gray-900">
                        Supported Format :- PNG, JPG and JEPG
                      </p>

                      <p className="text-md my-2 text-gray-900">
                        Image limit size upto 2mb
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Image</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    </Drawer>
  );
};

export default UnsplashImages;
