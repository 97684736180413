import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import UploadImage from "./UploadImage";
import { SET_PHOTO_PROPERTIES ,SET_PHOTO_LINK} from "../../../../../redux/reducerSlice/worldTrackSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IoMdArrowDropdown } from "react-icons/io";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

function PhotoEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.WorldTrack.data);

  const selectedPhoto = ardata.photos.find((data) => data?.id === whatsClicked?.userData?.id);
  const { id, geometry, animation } = selectedPhoto || {};
  const { delay, duration, direction } = animation || {};

  const [animationDelay, setAnimationDelay] = useState(delay || 0);
  const [animationDuration, setAnimationDuration] = useState(duration || 0);
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    setAnimationDelay(delay || 0);
    setAnimationDuration(duration || 0);
  }, [id, delay, duration]);

  const handleOpenModal = (modal) => setOpenModal(modal);
  const handleCloseModal = () => setOpenModal(null);

  const handleAnimationChange = (key, value) => {
    const action = {
      parentKey: "animation",
      key,
      value,
      id,
    };
    dispatch(SET_PHOTO_PROPERTIES(action));
  };

  const isAnimationPhoto = useSelector((state) => {
    const photo = state?.WorldTrack?.data?.photos?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );

    return photo?.isAnimation;
  });

  const photoDataId = {
    id: ardata?.photos?.find((data) => data?.id === whatsClicked?.userData?.id),
  };


  const handleGeometryChange = (event) => {
    const action = {
      key: "geometry",
      value: event.target.value,
      id,
    };
    dispatch(SET_PHOTO_PROPERTIES(action));
  };

  const photoLink = useSelector((state) => {
    const photo = state?.WorldTrack?.data?.photos?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return photo?.urlLink;
  });

  return (
    <div>
      {whatsClicked?.userData?.type === "photo" && (
        <>
          <div className="mt-2 px-2 flex flex-col gap-2">
            <div className="bg-white px-4 py-2 rounded-md shadow-md">
              <p className="py-2">Edit Image</p>
              <div className="flex w-full items-center justify-center">
                <button
                  className="w-full cursor-pointer bg-[#3D2FA9] rounded-md p-1 py-2 text-center text-white"
                  onClick={handleOpenModal}
                >
                  Replace Image
                </button>
                <UploadImage
                  openModal={openModal}
                  handleCloseModal={handleCloseModal}
                  id={id}
                />


              </div>
              <div className="border-t-1 flex w-full items-center justify-center">
                <RadioGroup
                  value={geometry}
                  className="w-full"
                  onChange={handleGeometryChange}
                  row
                >
                  <div className="flex w-full items-center justify-center">
                    <FormControlLabel
                      value="Plane"
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>Plane</span>}
                      className="w-full"
                    />
                    <FormControlLabel
                      value="Circle"
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>Circle</span>}
                      className="w-full"
                    />
                  </div>
                </RadioGroup>
              </div>

              <div className="text-bold mb-2 mt-2 flex w-full flex-col items-center text-xs text-gray-900">
                <div className="flex w-full gap-3 rounded-lg bg-white px-2">
                  <h1 className="flex items-center py-1">URL:</h1>
                  <input
                    type="text"
                    className="w-full border p-1 outline-none"
                    value={ photoLink}
                    onChange={(event) => {
                      const action = {
                        link: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_PHOTO_LINK(action));
                    }}
                  />
                </div>
              </div>


            </div>

            

          
  
          </div>
          <div className="mt-2 px-2">
            <div className="bg-white py-2 px-4 rounded-md shadow-md">
              <p className="py-1">Animation</p>


              {/* CheckBox for Photo animation enable */}
              {whatsClicked?.userData?.type === "photo" && (
                <div className="  border-t-2  text-sm ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAnimationPhoto}
                        onChange={() => {
                          const action = {
                            key: "isAnimation",
                            value: !isAnimationPhoto,
                            id: photoDataId.id.id,
                          };
                          console.log(isAnimationPhoto);
                          dispatch(SET_PHOTO_PROPERTIES(action));
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 14 }}>Apply</span>}
                  />
                </div>
              )}

              {/* Photo Animation Edit Bar  */}
              {whatsClicked?.userData?.type === "photo" && isAnimationPhoto && (
                <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                  <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Duration (ms)</label>
                    <input
                      id="duration"
                      type="number"
                      className="w-full border p-1"
                      value={animationDuration}
                      onChange={(event) => setAnimationDuration(event.target.valueAsNumber)}
                      onBlur={() => {
                        const action = {
                          parentKey: "Animation",
                          key: "duration",
                          value: animationDuration,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_PHOTO_PROPERTIES(action));
                      }}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Delay (ms)</label>
                    <input
                      id="delay"
                      type="number"
                      className="w-full border p-1"
                      value={animationDelay}
                      onChange={(event) => setAnimationDelay(event.target.valueAsNumber)}
                      onBlur={() => {

                        const action = {
                          parentKey: "Animation",
                          key: "delay",
                          value: animationDelay,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_PHOTO_PROPERTIES(action));
                      }}
                    />
                  </div>

                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Direction</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        ardata?.photos?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.direction || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "direction",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_PHOTO_PROPERTIES(action));
                      }}
                    >
                      <option value="topToBottom">Top to Bottom</option>
                      <option value="leftToRight">Left to Right</option>
                      <option value="rightToLeft">Right to Left</option>
                      <option value="bottomToTop">Bottom to Top</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PhotoEditorSection;
