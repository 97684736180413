import React, { useEffect, useState } from "react";
import { FiCalendar, FiFilter } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Pagination } from "@mui/material";
import RowsPerPageSelect from "./RowSelect";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { ConvolutionShader } from "three/examples/jsm/Addons";
import { FaExpandArrowsAlt } from "react-icons/fa";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import "jspdf-autotable";
const products = [
  { name: "Product 1", quantity: 2, price: 1000 },
  { name: "Product 2", quantity: 1, price: 800 },
];
const data = [
  {
    id: "xSQqNRRFhQN17DJDYGitL",
    date: "2024-06-22",
    name: "John Doe",
    mobile: "1234567890",
    price: 31120,
    paymentStatus: "success",
  },
  {
    id: "DLt9_IOHCuvn7LEERzAbp",
    date: "2024-06-20",
    name: "Jane Smith",
    mobile: "9876543210",
    price: 34000,
    paymentStatus: "success",
  },
  // ... other data items
];

const Orders = () => {
  const userToken = useSelector((state) => state?.auth?.auth?.token);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [orderCSV, setOrderCSV] = useState([]);

  const [ordersData, setOrdersData] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const handleFilter = () => {
    if (!tempStartDate || !tempEndDate) {
      return;
    }
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setIsAppliedFilter(true);
    setShowFilterMenu(false);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setTempStartDate("");
    setTempEndDate("");
    setIsAppliedFilter(false);
    setCurrentPage(1);
  };

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (value) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1);
  };

  const handleFilterClick = () => {
    setShowFilterMenu(!showFilterMenu);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleCloseFilter = () => {
    setShowFilterMenu(false);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const filteredData = data.filter((order) => {
    const orderDate = new Date(order.date);
    const isWithinDateRange =
      (!startDate || orderDate >= new Date(startDate)) &&
      (!endDate || orderDate <= new Date(endDate));

    const searchTerm = searchQuery.toLowerCase();
    const matchesSearch =
      !searchQuery ||
      order.name.toLowerCase().includes(searchTerm) ||
      order.mobile.includes(searchTerm) ||
      order.id.toLowerCase().includes(searchTerm);

    return isWithinDateRange && matchesSearch;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);
  const getOrderData = async () => {
    try {
      setLoadingOrders(true);
      const response = await instance.get(
        `api/tryOn/get_order_history?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}&startDate=${startDate}&endDate=${endDate}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log(response, "this is orde rdtaa");
        setOrdersData(response?.data?.data);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
    } finally {
      setLoadingOrders(false);
    }
  };
  const getOrderCSVData = async () => {
    try {
      const response = await instance.get(
        `api/tryOn/get_order_history_csv?startDate=${startDate}&endDate=${endDate}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log(response, "this is orde rdtaa");
        setOrderCSV(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getOrderData();
  }, [itemsPerPage, currentPage, searchParam, startDate, endDate]);
  useEffect(() => {
    getOrderCSVData();
  }, [startDate, endDate]);

  const getInvoiceData = async (id) => {
    try {
      const response = await instance.get(
        `api/tryOn/get_order_id/${id}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log(response, "this is orde rdtaa");
        const res = await generateInvoice(response?.data?.data);
      }
    } catch (error) {
      toast.error("Error while getting invoice data");
    }
  };

  const generateInvoice = (orderData) => {
    // Initialize PDF
    const doc = new jsPDF();

    // Add company logo/header
    doc.setFontSize(20);
    doc.text("INVOICE IMMARSIFY", 105, 15, { align: "center" });

    // Add invoice details
    doc.setFontSize(10);
    doc.text("Invoice Date:", 15, 30);
    doc.text(new Date(orderData.createdAt).toLocaleDateString(), 50, 30);

    doc.text("Invoice No:", 15, 35);
    doc.text(orderData._id, 50, 35);

    doc.text("Order ID:", 15, 40);
    doc.text(orderData.order_id, 50, 40);

    // Add billing details
    doc.setFontSize(12);
    doc.text("Billing Details:", 15, 55);
    doc.setFontSize(10);
    doc.text(`Name: ${orderData.fullName}`, 15, 62);
    doc.text(`Email: ${orderData.email}`, 15, 67);
    doc.text(`Phone: ${orderData.phoneNumber}`, 15, 72);

    // Add payment details
    doc.setFontSize(12);
    doc.text("Payment Details:", 120, 55);
    doc.setFontSize(10);
    doc.text(`Method: ${orderData.method}`, 120, 62);
    doc.text(`Status: ${orderData.status}`, 120, 67);
    doc.text(
      `Transaction ID: ${orderData.acquirer_data.bank_transaction_id}`,
      120,
      72
    );

    // Add products table
    const tableColumn = ["#", "Product", "Quantity", "Price", "Total"];
    const tableRows = orderData?.notes?.products.map((item, index) => [
      index + 1,
      item.productName,
      item.quantity,
      `Rs.${item.amount}`,
      `Rs.${item.amount * item.quantity}`,
    ]);

    doc.autoTable({
      startY: 85,
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      headStyles: { fillColor: [71, 71, 71] },
    });

    // Calculate final Y position after table
    const finalY = doc.previousAutoTable.finalY + 10;

    // Add total amount section
    doc.setFontSize(10);
    doc.text(`Subtotal:`, 120, finalY + 5);
    doc.text(`Rs.${orderData.amount / 100}`, 170, finalY + 5);

    // doc.text(`Tax:`, 120, finalY + 10);
    // doc.text(`Rs.${orderData.tax}`, 170, finalY + 10);

    // doc.text(`Fee:`, 120, finalY + 15);
    // doc.text(`Rs.${orderData.fee}`, 170, finalY + 15);

    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text(`Total Amount:`, 120, finalY + 25);
    doc.text(`Rs.${orderData.amount / 100}`, 170, finalY + 25);

    // Add footer
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Thank you for your business!", 105, finalY + 40, {
      align: "center",
    });

    // Save the PDF
    doc.save(`Invoice-${orderData.order_id}.pdf`);
  };
  return (
    <div className="container mx-auto px-4 py-6 ">
      <div className="mb-4 overflow-visible rounded-lg shadow-lg">
        <div className="rounded-lg bg-white px-4 py-3">
          <h1 className="text-xl font-bold text-[#979898]">Orders</h1>
        </div>

        <div className="bg-white px-4 py-6">
          <div className="mb-6 flex flex-col items-start justify-between gap-4 sm:items-center md:flex-row">
            {/* Search Input */}
            <div className="w-full md:w-1/3">
              <input
                type="text"
                placeholder="Search by name, mobile, or ID..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Filter and Export Controls */}
            <div className="flex w-full flex-col items-center gap-2 sm:w-auto sm:flex-row">
              <div className="relative">
                {isAppliedFilter ? (
                  <div className="flex items-center space-x-2 rounded-md border p-2 py-3 text-sm">
                    <FiCalendar size={20} className="text-blue-600" />
                    <span className="font-medium text-blue-800">
                      {moment(startDate).format("DD/MM/YYYY")} -{" "}
                      {moment(endDate).format("DD/MM/YYYY")}
                    </span>
                    <span onClick={handleClear} className="cursor-pointer">
                      <IoMdClose size={20} className="text-red-500" />
                    </span>
                  </div>
                ) : (
                  <button
                    onClick={handleFilterClick}
                    className="flex items-center space-x-2 rounded-md bg-blue-600 px-4 py-2 text-white"
                  >
                    <FiFilter />
                    <span>Filter by date</span>
                  </button>
                )}

                {showFilterMenu && (
                  <div className="absolute right-0 z-50 mt-2 w-72 rounded-md bg-white shadow-lg">
                    <div className="p-4">
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Start Date
                          </label>
                          <input
                            type="date"
                            value={tempStartDate}
                            onChange={(e) => setTempStartDate(e.target.value)}
                            className="mt-1 w-full rounded-md border p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            End Date
                          </label>
                          <input
                            type="date"
                            value={tempEndDate}
                            onChange={(e) => setTempEndDate(e.target.value)}
                            min={tempStartDate}
                            className="mt-1 w-full rounded-md border p-2"
                          />
                        </div>
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={handleCloseFilter}
                            className="rounded-md border px-3 py-1"
                          >
                            Close
                          </button>
                          <button
                            onClick={handleFilter}
                            className={`rounded-md px-3 py-1 text-white ${
                              tempStartDate && tempEndDate
                                ? "bg-blue-600 hover:bg-blue-700"
                                : "cursor-not-allowed bg-blue-300"
                            }`}
                            disabled={!tempStartDate || !tempEndDate}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <CSVLink
                data={orderCSV}
                filename="orders.csv"
                className="flex justify-center rounded-md bg-green-600 px-4 py-2 text-white"
              >
                Export to CSV
              </CSVLink>
              <RowsPerPageSelect onChange={handleChange} />
              {/* <div className="flex items-center">
                <span className="mr-2 text-sm">Rows per page:</span>
                <select
                  value={itemsPerPage}
                  onChange={handleChange}
                  className="rounded-md border bg-gray-50 p-1"
                >
                  {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>
          {loadingOrders ? (
            <div>
              {" "}
              <h2 className="text-center">Loading</h2>
            </div>
          ) : (
            <div className="overflow-x-auto">
              {ordersData.length > 0 ? (
                <table className="min-w-full bg-white">
                  <thead style={{ background: "#f4f4ff" }}>
                    <tr>
                      <th className="border px-4 py-2 text-start">ID</th>
                      <th className="border px-4 py-2 text-start">DATE</th>
                      <th className="border px-4 py-2 text-start">NAME</th>
                      <th className="border px-4 py-2 text-start">MOBILE</th>
                      <th className="border px-4 py-2 text-start">PRICE</th>
                      <th className="border px-4 py-2 text-start">
                        PAYMENT STATUS
                      </th>
                      <th className="border px-4 py-2 text-start">INVOICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersData.map((order) => (
                      <tr key={order?.order_id}>
                        <td className="border px-4 py-2">{order?.order_id}</td>
                        <td className="border px-4 py-2">
                          {moment(order?.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="border px-4 py-2">{order?.fullName}</td>
                        <td className="border px-4 py-2">
                          {order?.phoneNumber}
                        </td>
                        <td className="border px-4 py-2">
                          {order?.amount / 100}
                        </td>
                        <td
                          className={`border px-4 py-2 ${
                            order?.captured ? "text-green-500" : "text-red-500"
                          }`}
                        >
                          {order?.captured ? "success" : "failed"}
                        </td>
                        <td
                          className="cursor-pointer border px-4 py-2 text-blue-500"
                          onClick={() => getInvoiceData(order?.order_id)}
                        >
                          Download
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="py-8 text-center">
                  <p className="text-lg text-gray-500">No orders found</p>
                </div>
              )}
            </div>
          )}

          {!loadingOrders && ordersData.length > 0 && (
            <div className="mt-4 flex items-center justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChanges}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
