import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import ProtectedRoute from "protectedRoute";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Template");
  const [isSidebarNeeded, setIsSideBarNeeded] = React.useState();
  const [isNabBarNeeded, setIsNabBarNeeded] = React.useState();
  const [isFooterNeeded, setIsFooterNeeded] = React.useState();

  const closeSideBar = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = routes;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        setIsSideBarNeeded(routes[i]?.isSidebarNeeded);
        setIsNabBarNeeded(routes[i]?.isNabBarNeeded);
        setIsFooterNeeded(routes[i]?.isFooterNeeded);
      }
    }
    return activeRoute;
  };
  let { userId } = useParams();
  console.log(userId, "paramsvalue");
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href?.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      return (
        <Route
          path={`/${prop?.path}${prop?.subPath ?? ""}`}
          element={
            prop?.isProtected ? (
              <ProtectedRoute element={prop.component} />
            ) : (
              prop.component
            )
          }
          key={key}
        />
      );
    });
  };

  return (
    <div className="main-1 flex h-full w-full">
      {isSidebarNeeded && <Sidebar open={open} onClose={closeSideBar} />}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[20] h-full flex-none transition-all  ${
            isSidebarNeeded ? "xl:ml-[250px]" : ""
          }`}
        >
          <div className="h-full">
            {isNabBarNeeded && (
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                logoText={"Immarsify"}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                {...rest}
              />
            )}
            {location?.pathname === "/admin/custom-card" ? (
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="*"
                  element={<Navigate to="/admin/template" replace />}
                />
              </Routes>
            ) : (
              <div
                className={`mx-auto ${
                  currentRoute !== "experience" && "p-[5px]"
                } mb-auto h-full min-h-[84vh]  pb-2`}
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="*"
                    element={<Navigate to="/admin/template" replace />}
                  />
                </Routes>
              </div>
            )}
            {isFooterNeeded && (
              <div className="p-3">
                <Footer />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
