import { instance } from 'common/Instance';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const RazorPayDetails = () => {
    const [loading, setLoading] = useState(false);
    const [showKeyId, setShowKeyId] = useState(false);
    const [showSecretKey, setShowSecretKey] = useState(false);
    const [formData, setFormData] = useState({
        RazorPayKeyId: '',
        RazorPaySecretKey: ''
    });
    const [showUpdateForm, setShowUpdateForm] = useState(true);
    const user = useSelector((state) => state?.auth?.auth?.user);
    const userToken = useSelector((state) => state.auth.auth.token);

    useEffect(() => {
        if(user?.RazorPayKeyId && user?.RazorPaySecretKey){
            setShowUpdateForm(false)
        }
    },[])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!formData.RazorPayKeyId.trim() || !formData.RazorPaySecretKey.trim()) {
            toast.error('Please fill in all fields');
            return;
        }

        setLoading(true);
        const loadingToast = toast.loading('Updating RazorPay settings...');

        try {
            const response = await instance.post('/api/tryOn/updateRazorPayCredential', formData,
                {
                    headers: {
                        authorization: `Bearer ${userToken}`,
                    },
                }
            );

            console.log("response of update rzor pay cred", response);

            if (response && response?.data) {
                toast.success('RazorPay Credentials updated successfully!', {
                    id: loadingToast,
                });

                // Clear form
                setFormData({
                    RazorPayKeyId: '',
                    RazorPaySecretKey: ''
                });
                setShowUpdateForm(false)
            }

        } catch (error) {
            console.error('Error updating RazorPay settings:', error);
            toast.error('Failed to update credentials', {
                id: loadingToast,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full drop-shadow-sm bg-white flex flex-col items-stretch justify-center gap-4 rounded-2xl p-4 sm:p-6 lg:p-8 mx-auto">
            <div className=' flex flex-col md:flex-row items-start gap-4 md:gap-0 md:items-center justify-center md:justify-between'>
                <div className='flex flex-col items-start justify-center'>
                    <h2 className="text-2xl font-bold text-gray-900">RazorPay Settings</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Configure your RazorPay integration details
                    </p>
                </div>

                {!showUpdateForm && <button
                    type="button"
                    onClick={() => setShowUpdateForm(true)}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-brand-main transition-colors"
                >
                    Update Details
                </button>
                }

            </div>
            {
                showUpdateForm && (
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4">
                        {/* Key ID Field */}
                        <div>
                            <label
                                htmlFor="razorPayKeyId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                RazorPay Key ID
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type={showKeyId ? "text" : "password"}
                                    name="RazorPayKeyId"
                                    id="razorPayKeyId"
                                    value={formData.RazorPayKeyId}
                                    onChange={handleChange}
                                    className="block w-full pr-10 border-2 border-gray-100 rounded-lg pl-3 py-2 text-gray-900 placeholder-gray-500 disabled:bg-gray-100 disabled:cursor-not-allowed transition-colors"
                                    placeholder="Enter your RazorPay Key ID"
                                    disabled={loading}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowKeyId(!showKeyId)}
                                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                                >
                                    {showKeyId ? (
                                        <FaEyeSlash className="h-5 w-5 text-gray-400" />
                                    ) : (
                                        <FaEye className="h-5 w-5 text-gray-400" />
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Secret Key Field */}
                        <div>
                            <label
                                htmlFor="razorPaySecretKey"
                                className="block text-sm font-medium text-gray-700"
                            >
                                RazorPay Secret Key
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type={showSecretKey ? "text" : "password"}
                                    name="RazorPaySecretKey"
                                    id="razorPaySecretKey"
                                    value={formData.RazorPaySecretKey}
                                    onChange={handleChange}
                                    className="block w-full pr-10 border-2 border-gray-100 rounded-lg pl-3 py-2 text-gray-900 placeholder-gray-500 disabled:bg-gray-100 disabled:cursor-not-allowed transition-colors"
                                    placeholder="Enter your RazorPay Secret Key"
                                    disabled={loading}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowSecretKey(!showSecretKey)}
                                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                                >
                                    {showSecretKey ? (
                                        <FaEyeSlash className="h-5 w-5 text-gray-400" />
                                    ) : (
                                        <FaEye className="h-5 w-5 text-gray-400" />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className=' hidden md:block'></div>

                        {/* Submit Button */}
                        <div className="flex justify-end gap-2">
                            <button
                                type="button"
                                onClick={() => setShowUpdateForm(false)}
                                className="inline-flex items-center px-4 py-2 border-2 rounded-lg shadow-sm font-medium border-brand-400 text-brand-400  transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-brand-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-brand-main disabled:bg-blueSecondary disabled:cursor-not-allowed transition-colors"
                            >
                                {loading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Updating...
                                    </>
                                ) : (
                                    'Update Settings'
                                )}
                            </button>
                        </div>
                    </form>
                )
            }
        </div>
    );
};

export default RazorPayDetails;