import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TextField } from "@mui/material";
import { UPDATE_TEXT_PROPERTIES } from "../../../../../redux/reducerSlice/worldTrackSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IoMdArrowDropdown } from "react-icons/io";

function TextEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.WorldTrack.data);
  const isAnimationText = useSelector((state) => {
    const text = state?.WorldTrack?.data?.text?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    return text?.isAnimation;
  });

  const name = {
    id: ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.id,
  };

  const [animationDelay, setAnimationDelay] = useState(
    ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.delay
  );

  const [animationDuration, setAnimationDuration] = useState(
    ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.duration
  );

  useEffect(() => {
    setAnimationDelay(
      ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.delay
    );

    setAnimationDuration(
      ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.duration
    );
  }, [whatsClicked?.userData?.id]);

  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    const data = ardata.text.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    if (data) {
      setTextValue(data.body);
    }
  }, [ardata, whatsClicked]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTextValue(newValue);

    // Dispatch the action with the updated value
    const action = {
      key: "body",
      value: newValue,
      id: name.id,
    };
    dispatch(UPDATE_TEXT_PROPERTIES(action));
  };

  return (
    <div>
      {whatsClicked?.userData?.type === "text" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 text-sm shadow-md">
            <div className="w-full text-sm">
              <label className="w-full text-gray-900">Edit Text</label>
              <TextField
                rows={2}
                multiline
                type="text"
                className="w-full"
                value={textValue}
                onChange={handleChange}
              />
            </div>

            <div className="my-2 w-full text-sm">
              <div className="my-2 flex w-full items-center justify-start gap-2 text-sm text-gray-900">
                <h1 className="w-full">Color</h1>
                <div className="flex w-full justify-start">
                  <input
                    type="color"
                    value={ardata.text
                      .filter((data) => data.id === whatsClicked?.userData?.id)
                      .map((data) => data.color)}
                    onChange={(event) => {
                      const action = {
                        key: "color",
                        value: event.target.value,
                        id: name.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>
              </div>

              <div className="my-2 flex w-full items-center justify-start gap-1 text-sm text-gray-900">
                <h1 className="w-full">Size (px)</h1>
                <div className="flex w-full justify-start">
                  <input
                    type="number"
                    className="w-full px-1"
                    value={ardata.text
                      .filter((data) => data.id === whatsClicked?.userData?.id)
                      .map((data) => data.fontSize)}
                    onChange={(event) => {
                      const action = {
                        key: "fontSize",
                        value: event.target.value,
                        id: name.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "text" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p>Animation</p>

            {whatsClicked?.userData?.type === "text" && (
              <div className=" border-t-2   ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnimationText}
                      onChange={() => {
                        const action = {
                          key: 'isAnimation',
                          value: !isAnimationText,
                          id: name.id,
                        };
                        dispatch(UPDATE_TEXT_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 13 }}>Apply</span>}
                />
              </div>
            )}
            {whatsClicked?.userData?.type === "text" && isAnimationText && (
              <div className="my-1 flex flex-col gap-4 bg-white text-sm">
                <div className="flex flex-col justify-center">
                  <label className="text-gray-900">Duration (ms)</label>
                  <input
                    id="duration"
                    type="number"
                    className="w-full border p-1"
                    value={animationDuration}
                    onChange={(event) =>
                      setAnimationDuration(event.target.valueAsNumber)
                    }
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "duration",
                        value: animationDuration,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <label className="text-gray-900">Delay (ms)</label>
                  <input
                    id="delay"
                    type="number"
                    className="w-full border p-1"
                    value={animationDelay}
                    onChange={(event) =>
                      setAnimationDelay(event.target.valueAsNumber)
                    }
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "delay",
                        value: animationDelay,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <label className="text-gray-900">Direction</label>
                  <select
                    name=""
                    id=""
                    className="w-full border p-1"
                    value={
                      ardata?.text?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                      )?.animation.direction || ""
                    }
                    onChange={(event) => {
                      const action = {
                        parentKey: "Animation",
                        key: "direction",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  >
                    <option value="topToBottom">Top to Bottom</option>
                    <option value="leftToRight">Left to Right</option>
                    <option value="rightToLeft">Right to Left</option>
                    <option value="bottomToTop">Bottom to Top</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TextEditorSection;
