
import CheckTable from "views/admin/Reseller/components/CheckTable";


const Userlist = () => {
  return (
    <div className=" grid grid-cols-1 gap-5  md:grid-cols-1">
      <CheckTable />
    </div>
  );
};

export default Userlist;
