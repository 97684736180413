import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import * as Yup from "yup";

import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { IoMdClose } from "react-icons/io";
import { MenuItem, Modal, Select, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { ConvolutionShader } from "three/examples/jsm/Addons";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useEffect } from "react";
import { SliderPicker } from "react-color";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tryOnTypes = [
  {
    id: 1,
    value: 'sunglasses',
    text: "Sun Glasses"
  },
  {
    id: 2,
    value: 'earring',
    text: 'Ear Rings'
  },
  {
    id: 3,
    value: 'necklace',
    text: 'Necklace'
  }
]

export default function EditProductModal({
  isOpen,
  setIsOpen,
  product,
  getImmersifyProducts,
}) {
  console.log(product, "this is product");
  const [productAvailableCheck, setProductAvailableCheck] = useState(
    product?.isProductAvailable ? true : false
  );
  const [variantsCheck, setVarientCheck] = useState(false);
  const [recommandCheck, setRecommandCheck] = useState(false);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  // const [selectedGallaryImagesLink, setSelectedGallaryImagesLink] = useState([]);
  const [selectedTryOnImage, setSelectedTryOnImage] = useState(null);
  const [selectedGallaryImages, setSelectedGallaryImages] = useState([]);
  const [uploadedGallaryImagesLink, setUploadedGallaryImagesLink] = useState(
    product?.gallaryImages ? product?.gallaryImages : []
  );
  const [uploadedMainImage, setUploadedMainImage] = useState(
    product?.mainImage ? product?.mainImage : null
  );
  const [uploadedTryOnImages, setUploadedTryOnImages] = useState(product?.glassImages);

  // const [mainImageLoading, setMainImageLoading] = useState(false);
  // const [tryOnImageLoading, setTryOnImageLoading] = useState(false);
  // const [gallaryImageLoading, setGallaryImageLoading] = useState(false);
  const [selectedMainImageBinary, setSelectedMainImageBinary] = useState(null);
  const [selectedGallaryImageBinary, setSelectedGallaryImageBinary] = useState(
    []
  );
  const [selectedTryOnImageBinary, setSelectedTryOnImageBinary] = useState(null);
  const [selectedTryOnType, setSelectedTryOnType] = useState(null)

  // const [productInputField, setProductInputField] = useState({
  //   name: product?.name,
  //   description: product?.description,
  //   price: product?.price,
  //   taxPercentage: product?.taxPercentage,
  // });

  const [tryOnImagesBinary, setTryOnImagesBinary] = useState([])
  const [openTryOnModal, setOpenTryOnModal] = useState(false);
  const [tryOnImages, setTryOnImages] = useState([]);
  const [color, setColor] = useState('');
  const [tryOnColors, setTryOnColors] = useState(product?.glassColors);
  const [newTryOnColors, setNewTryOnColors] = useState([])

  const userToken = useSelector((state) => state.auth.auth.token);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .required("Description is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .positive("Price must be positive")
      .required("Price is required"),
    taxPercentage: Yup.number()
      .typeError("Tax percentage must be a number")
      .min(0, "Tax percentage cannot be less than 0")
      .max(100, "Tax percentage cannot be more than 100")
      .required("Tax percentage is required"),
    comparisionUrl : Yup.string()
  });

  useEffect(() => {
    const category = tryOnTypes.find((item) => item.value === product?.glassImageType)

    console.log("try on cate", category);

    if (category) {
      setSelectedTryOnType(category)
    } else {
      setSelectedTryOnType({ id: 1, value: 'sunglasses', text: "Sun Glasses" })
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      name: product?.name ? product?.name : "",
      description: product?.description ? product?.description : "",
      price: product?.price ? product?.price : 0,
      taxPercentage: product?.taxPercentage ? product?.taxPercentage : 0,
      comparisionUrl : product?.comparisionUrl ? product?.comparisionUrl : ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      updateProductHandler(values);
      console.log(values, "form is sumitted");
    },
  });

  // const inputChangeHandler = (e) => {
  //   const { value, name } = e.target;
  //   setProductInputField((prev) => {
  //     return { ...prev, [name]: value };
  //   });
  // };

  // const updloadImageHandler = async (imageFile) => {
  //   try {
  //     if (!imageFile) {
  //       return toast.error("Please select image");
  //     }
  //     const formData = new FormData();
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920,
  //       useWebWorker: true,
  //     };
  //     const compressedBlob = await imageCompression(imageFile, options);

  //     // Convert Blob to File
  //     const compressedFile = new File([compressedBlob], imageFile.name, {
  //       type: imageFile.type,
  //       lastModified: Date.now(),
  //     });

  //     formData.append("file", compressedFile);
  //     formData.append("assetType", "image");
  //     const fileName = compressedFile.name.split(".").slice(0, -1).join(".");

  //     const response = await instance.post(
  //       "api/products/upload_product_image",
  //       formData,
  //       {
  //         headers: {
  //           authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200 && response?.data?.url) {
  //       return response.data?.url;
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     return null;
  //   }
  // };

  // const updloadGallaryImagesHandler = async () => {
  //   try {
  //     if (selectedGallaryImages.length === 0) {
  //       toast.error("Please select images to upload");
  //       return;
  //     }

  //     setGallaryImageLoading(true);

  //     setUploadedGallaryImagesLink([]);

  //     for (let image of selectedGallaryImageBinary) {
  //       const link = await updloadImageHandler(image);

  //       setUploadedGallaryImagesLink((prev) => {
  //         return [...prev, link];
  //       });
  //     }
  //     // selectedGallaryImageBinary.forEach(async (image) => {
  //     //   const link = await updloadImageHandler(image);
  //     //   setUploadedGallaryImagesLink((prev) => {
  //     //     return [...prev, link];
  //     //   });
  //     // });

  //     setGallaryImageLoading(false);
  //     toast.success("Images uploaded successfully");
  //     setSelectedGallaryImages([]);
  //     setSelectedGallaryImageBinary([]);
  //   } catch (error) {
  //     setGallaryImageLoading(false);
  //     setSelectedGallaryImages([]);
  //     setSelectedGallaryImageBinary([]);
  //   }
  // };

  // const uploadMainImageHandler = async () => {
  //   try {
  //     if (!selectedMainImageBinary) {
  //       toast.error("Please select image");
  //       return;
  //     }

  //     setMainImageLoading(true);
  //     const link = await updloadImageHandler(selectedMainImageBinary);
  //     setUploadedMainImage(link);
  //     setSelectedMainImage(null);
  //     toast.success("Image uploaded successfully");
  //     setSelectedMainImageBinary(null);
  //     setMainImageLoading(false);
  //   } catch (error) {
  //     setSelectedMainImage(null);
  //     setSelectedMainImageBinary(null);
  //     setMainImageLoading(false);
  //   }
  // };

  const handleMainImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      const imageUrl = URL.createObjectURL(files[0]);
      setSelectedMainImage(imageUrl);

      setSelectedMainImageBinary(files[0]);
    } catch (error) { }
  };

  const handleGallaryImageChange = async (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
    );

    const imageUrls = validFiles.map((file) => URL.createObjectURL(file));
    setSelectedGallaryImages(imageUrls);
    console.log(validFiles, "uploding image");
    setSelectedGallaryImageBinary(validFiles);
    // validFiles.forEach(async (file) => {
    //   const link = await updloadImageHandler(file);
    //   console.log(link, "this is link");
    //   setSelectedGallaryImagesLink((prev) => {
    //     return [...prev, link];
    //   });
    // });
    // setSelectedGallaryImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleTryOnImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      console.log(files);
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      console.log(files, validFiles);
      if (validFiles.length < 1) {
        toast.error("Please upload valid image");
        return;
      }
      const imageUrl = URL.createObjectURL(validFiles[0]);
      setSelectedTryOnImage(imageUrl);

      setSelectedTryOnImageBinary(validFiles[0]);
    } catch (error) {
      console.log("error try on image upload ", error);

    }
  };

  const handleDone = () => {
    if (!color || !selectedTryOnImage) {
      toast.error("Please enter color and select an image");
      return;
    }

    // const newTryOnImage = {
    //   color: color,
    //   image: selectedTryOnImage
    // };
    // const newTryOnImageBinary = {
    //   color: color,
    //   image: selectedTryOnImageBinary
    // };



    setTryOnImages((prevImages) => [...prevImages, selectedTryOnImage]);
    setNewTryOnColors((prev) => [...prev, color])
    setTryOnImagesBinary((prev) => [...prev, selectedTryOnImageBinary])
    setOpenTryOnModal(false);
    setColor('');
    setSelectedTryOnImage(null);
    setSelectedTryOnImageBinary(null);
  };

  // const uploadTryOnImageHandler = async () => {
  //   try {
  //     if (!selectedTryOnImageBinary) {
  //       toast.error("Please select image");
  //       return;
  //     }

  //     setTryOnImageLoading(true);
  //     const link = await updloadImageHandler(selectedTryOnImageBinary);
  //     setUploadedTryOnImage(link);
  //     setSelectedTryOnImage(null);
  //     setSelectedTryOnImageBinary(null);
  //     setTryOnImageLoading(false);
  //   } catch (error) {
  //     setSelectedTryOnImage(null);
  //     setSelectedTryOnImageBinary(null);
  //     setTryOnImageLoading(false);
  //   }
  // };

  // const handleClickOpen = () => {
  //   setIsOpen(true);
  // };

  const handleClose = () => {
    setIsOpen(false);
  };

  const featureHandler = (type) => {
    if (type === "productAvailable") {
      setProductAvailableCheck((prev) => !prev);
    }
    if (type === "recommand") {
      setRecommandCheck((prev) => !prev);
    }
    if (type === "variants") {
      setVarientCheck((prev) => !prev);
    }
  };

  const filterGallaryImages = (mainIndex) => {
    const imgs = selectedGallaryImages.filter(
      (img, index) => mainIndex !== index
    );
    setSelectedGallaryImages(imgs);
  };

  // const updateProductHandler = async (values) => {
  //   try {
  //     const { name, description, taxPercentage, price } = values;
  //     const response = await instance.put(
  //       `api/products/update_product/${product?._id}`,
  //       {
  //         name: name,
  //         description: description,
  //         price: price,
  //         taxPercentage: taxPercentage,
  //         mainImage: uploadedMainImage,
  //         glassImage : {
  //           url : uploadedTryOnImage,
  //           type : selectedTryOnType
  //         },
  //         gallaryImages: uploadedGallaryImagesLink,
  //         isProductAvailable: productAvailableCheck,
  //         recommand: recommandCheck,
  //       },
  //       {
  //         headers: {
  //           authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     console.log(response?.data, "this is repsonse");
  //     if (response?.status === 200) {
  //       getImmersifyProducts();
  //       toast.success(response?.data?.message);
  //       setTimeout(() => {
  //         setIsOpen(false);
  //       }, 500);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message || "Something went wrong");
  //     console.log("Dashboard Error: ", error);
  //   }
  // };

  const updateProductHandler = async (values) => {
    try {
      const { name, description, taxPercentage, price } = values;

      const formData = new FormData();
      formData.append("name", formik.values.name);
      formData.append("description", formik.values.description);
      formData.append("price", formik.values.price);
      formData.append("taxPercentage", formik.values.taxPercentage);
      formData.append("productAvailable", productAvailableCheck);
      // formData.append('mainImage', uploadedMainImage)
      formData.append('recommand', recommandCheck)
      formData.append('comparisionUrl', formik.values.comparisionUrl)
      // formData.append('glassImages', JSON.stringify({
      //   url: uploadedTryOnImage,
      //   type: selectedTryOnType?.value
      // }))
      // uploadedTryOnImages?.map((item) => {
      //   formData?.append('glassImages', JSON.stringify(item))
      // })

      const upTryimages = Array.isArray(uploadedTryOnImages) ? uploadedTryOnImages : [uploadedTryOnImages];

      // Append each image to formData
      upTryimages.forEach(item => {
        formData.append('glassImages', JSON.stringify(item));  // Ensure item is appended as a JSON string
      });

      if (selectedTryOnType?.value !== product?.glassImage?.type) {
        formData.append('newGlassImageType', selectedTryOnType?.value)
      }

      if (uploadedGallaryImagesLink?.length > 0) {
        uploadedGallaryImagesLink.forEach((file, index) => {
          formData.append('gallaryImages', file)
        })
      }

      if (selectedMainImageBinary) {
        formData.append("newMainImage", selectedMainImageBinary);
      }

      if (selectedGallaryImageBinary?.length > 0) {
        selectedGallaryImageBinary.forEach((file, index) => {
          formData.append(`newGalleryImages`, file);
        });
      }

      // if (selectedTryOnImageBinary) {
      //   formData.append("newGlassImage", selectedTryOnImageBinary)
      // }

      tryOnImagesBinary?.forEach(item => {
        formData?.append('newGlassImages', item)
      })

      newTryOnColors?.forEach(color => {
        formData?.append('newGlassColors', color)
      })

      // tryOnImagesBinary?.forEach((item, index) => {
      //   formData.append(`glassImages[${index}][color]`, item.color);
      //   formData.append(`glassImages[${index}][image]`, item.image);
      // });
      formData.append('glassImageType', selectedTryOnType?.value);



      const response = await instance.put(
        `api/products/update_product/${product?._id}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response?.data, "this is repsonse");
      if (response?.status === 200) {
        getImmersifyProducts();
        toast.success(response?.data?.message);
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };

  const handleTryOnTypeChange = (e) => {
    const category = tryOnTypes.find(
      (category) => category?.value === e.target.value
    );
    console.log(category, "category found");
    if (category) {
      setSelectedTryOnType(category);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", background: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Product
            </Typography>
            <buttton
              onClick={formik.handleSubmit}
              className="   cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main/90 hover:bg-brand-main px-4 py-2 text-white outline-none transition duration-300 ease-in-out"
            >
              Update
            </buttton>
          </Toolbar>
        </AppBar>
        <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4 p-6 sm:px-12">
          <div className="w-full md:w-6/12 flex flex-col gap-4">
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Product to Management</h3>
              </div>
              <div className=" p-6">
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="name"
                    className="leading-7 text-black font-medium"
                  >
                    Name
                  </label>
                  <TextField
                    id="name outlined-basic"
                    name="name"
                    placeholder="John Wick"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    // variant="none"
                    className=" bg-black/5"
                  />
                  {/* <input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  type="text"
                  id="name"
                  name="name"
                  className="w-full rounded-md border border-gray-200 bg-black/5 px-3 py-1 text-base leading-8 text-gray-900 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                /> */}
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="description"
                    className="leading-7 text-black font-medium"
                  >
                    Description
                  </label>
                  <TextField
                    id="description outlined-basic"
                    name="description"
                    multiline
                    minRows={2}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className="bg-black/5"
                  />
                  {/* <textarea
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  rows={2}
                 
                  className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                /> */}
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="relative  w-full md:w-1/2 md:mb-4 flex flex-col">
                    <label
                      for="price"
                      className="leading-7 text-black font-medium"
                    >
                      Price
                    </label>
                    <TextField
                      id="price outlined-basic"
                      name="price"
                      onChange={formik.handleChange}
                      value={formik.values.price}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      type="number"
                      className="bg-black/5"
                    />
                    {/* <input
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    onBlur={formik.handleBlur}
                    id="price"
                    name="price"
                    type="number"
                    className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                  /> */}
                    {formik.touched.price && formik.errors.price ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.price}
                      </div>
                    ) : null}
                  </div>
                  <div className="relative mb-4 w-full md:w-1/2 flex flex-col ">
                    <label
                      for="taxPercentage"
                      className="leading-7 text-black font-medium"
                    >
                      Tax Perccentage
                    </label>
                    <TextField
                      id="taxPercentage outlined-basic"
                      name="taxPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.taxPercentage}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      type="number"
                      className="bg-black/5"
                    />
                    {/* <input
                    onChange={formik.handleChange}
                    value={formik.values.taxPercentage}
                    onBlur={formik.handleBlur}
                    id="taxPercentage"
                    name="taxPercentage"
                    type="number"
                    className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                  /> */}
                    {formik.touched.taxPercentage &&
                      formik.errors.taxPercentage ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.taxPercentage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <label
                    className="leading-7 text-black font-medium"
                  >
                    Product available
                  </label>
                  <span>
                    <Switch
                      checked={productAvailableCheck}
                      onChange={() => {
                        featureHandler("productAvailable");
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </span>
                </div>
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="comparisionUrl"
                    className="leading-7 text-black font-medium"
                  >
                    Comparision Url (optional)
                  </label>
                  <TextField
                    id="comparisionUrl outlined-basic"
                    name="comparisionUrl"
                    placeholder="John Wick"
                    onChange={formik.handleChange}
                    value={formik.values.comparisionUrl}
                    onBlur={formik.handleBlur}
                    size="small"
                    // variant="none"
                    className=" bg-black/5"
                  />
                  
                </div>
              </div>
            </div>
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Main Image</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">

                <label for="dropzone-file" className="flex flex-col text-center items-center justify-center w-11/12 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-3 pb-3">
                    <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Main Image</p>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file" type="file" accept=".jpg, .jpeg, .png, .svg" className="hidden" onChange={handleMainImageChange} />
                </label>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-start justify-center">

                  {selectedMainImage && (
                    <div className="selectedImage bg-gray-200 h-48 rounded-lg flex flex-col items-center justify-between">
                      <h4 className="text-center">Selected Image</h4>
                      <div className="relative shadow-md">
                        <span
                          className="absolute right-0 top-0 cursor-pointer"
                          onClick={() => {
                            setSelectedMainImage(null);
                          }}
                        >
                          <IoCloseCircleSharp color="red" size={28} />{" "}
                        </span>
                        <img
                          className="shadow-md object-cover rounded-b-lg h-40"
                          src={selectedMainImage}
                          alt=""
                        />
                      </div>
                    </div>
                  )}

                  {uploadedMainImage && (
                    <div className="uploadedImage bg-gray-200 h-48 rounded-lg flex flex-col items-center justify-between">
                      <h4 className="text-center">Uploaded images</h4>
                      <img
                        src={uploadedMainImage}
                        alt=""
                        className="shadow-md object-cover rounded-b-lg h-40"
                      />
                    </div>
                  )}

                </div>
                {/* {
                  selectedMainImage
                  &&
                  <button
                    disabled={mainImageLoading}
                    onClick={uploadMainImageHandler}
                    className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${mainImageLoading
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                  >
                    {mainImageLoading ? "Uploading" : "Upload"}
                  </button>
                } */}
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex flex-col gap-4">
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Gallery</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">

                <label for="dropzone-file-multiple" className="flex flex-col text-center items-center justify-center w-11/12 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-3 pb-3">
                    <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Gallery Images</p>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file-multiple" type="file" accept=".jpg, .jpeg, .png, .svg" multiple className="hidden" onChange={handleGallaryImageChange} />
                </label>
                <div className="w-full px-6">

                  {selectedGallaryImages?.length > 0 && (
                    <div className="selectedImage mb-4 w-full flex flex-col items-center justify-center gap-2 border-2 border-black/10 bg-black/5 rounded-lg p-4">
                      <h4 className="text-black ">Selected Images</h4>
                      <div className="flex items-center justify-center flex-wrap gap-2 w-full">
                        {selectedGallaryImages.map((img, mainIndex) => (
                          <div className="relative " key={mainIndex}>
                            <span
                              className="absolute right-0 top-0 cursor-pointer"
                              onClick={() => {
                                filterGallaryImages(mainIndex);
                              }}
                            >
                              <IoCloseCircleSharp color="red" size={20} />{" "}
                            </span>
                            <img
                              className="object-cover rounded-lg h-32"
                              src={img}
                              alt="gallery image"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {uploadedGallaryImagesLink?.length > 0 && (
                    <div className="uploadedImage w-full flex flex-col items-start justify-center gap-2 border-2 border-black/10 bg-black/5 rounded-lg p-4">
                      <h4 className="text-black">Uploaded Images</h4>
                      <div
                        className="flex items-center justify-start flex-wrap gap-2 w-full"

                      >
                        {uploadedGallaryImagesLink?.map((img, index) => (
                          <img
                            key={index}
                            className="object-cover rounded-lg h-32"
                            src={img}
                            alt="uploaded gallery image"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* {selectedGallaryImages?.length > 0
                  &&
                  <button
                    disabled={gallaryImageLoading}
                    onClick={updloadGallaryImagesHandler}
                    className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${gallaryImageLoading
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                  >
                    {gallaryImageLoading ? "Uploading" : "Upload"}
                  </button>
                } */}


              </div>
            </div>
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Try On Image</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">
                {
                  selectedTryOnType
                  &&
                  <Select
                    size="small"
                    labelId="category-select-label"
                    value={selectedTryOnType?.value}
                    onChange={handleTryOnTypeChange}
                    className="rounded-md border-gray-50 outline-none place-self-start ml-4 sm:ml-6"
                  >
                    {tryOnTypes.map((type, index) => (
                      <MenuItem key={index} value={type?.value}>
                        {type?.text}
                      </MenuItem>
                    ))}
                  </Select>
                }

                <div onClick={() => setOpenTryOnModal(true)} className="p-4 rounded-lg flex flex-col items-center justify-center border border-gray-200 hover:bg-gray-200 cursor-pointer">
                  <svg className="w-20 h-20 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                  </svg>
                  <span>Click to upload Try-On Image</span>
                </div>

                <Modal open={openTryOnModal} onClose={() => setOpenTryOnModal(false)} className="flex justify-center items-center">
                  <div className="bg-white p-6 rounded-lg w-96">
                    <h2 className="text-xl mb-4">Upload Try On Image</h2>

                    {/* Color Input */}
                    {/* <TextField
                      label="Color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      fullWidth
                      className="mb-4"
                    /> */}
                    <SliderPicker
                      color={color}
                      onChangeComplete={(e) => setColor(e.hex)}
                    />

                    <label for="try-on-image-upload" className="mt-2 flex flex-col text-center items-center justify-center w-full my-2 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <div className="flex flex-col items-center justify-center pt-3 pb-3">
                        <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Try On Image</p>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                      </div>
                      <input id="try-on-image-upload" type="file" accept=".jpg, .jpeg, .png, .svg" className="hidden" onChange={handleTryOnImageChange} />
                    </label>

                    {selectedTryOnImage && (
                      <div className="mb-4 text-center">
                        <img className="object-contain h-40 w-full" src={selectedTryOnImage} alt="Selected Try On" />
                      </div>
                    )}

                    {/* Done Button */}
                    <Button onClick={handleDone} variant="contained" color="primary" fullWidth>
                      Done
                    </Button>
                  </div>
                </Modal>

                {/* <label for="try-on-image-upload" className="flex flex-col text-center items-center justify-center w-11/12 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-3 pb-3">
                    <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Try On Image</p>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="try-on-image-upload" type="file" accept=".jpg, .jpeg, .png, .svg" className="hidden" onChange={handleTryOnImageChange} />
                </label> */}
                <div className=" flex items-center justify-center flex-wrap gap-2">
                  {tryOnImages?.map((item, index) => (
                    <div className="selectedImage bg-gray-200 h-48 rounded-lg flex flex-col items-center justify-between">
                      <h4 className="text-center flex gap-4 items-center justify-between">{newTryOnColors[index]}
                        <span
                          className="right-0 top-0 cursor-pointer"
                          onClick={() => {
                            const updatedTryOnImages = tryOnImages?.filter((item, i) => i !== index)
                            const updatedTryOnColors = tryOnColors?.filter((item, i) => i !== index)
                            const updatedTryOnImagesBinary = tryOnImagesBinary?.filter((item, i) => i !== index)
                            setTryOnColors(updatedTryOnColors)
                            setTryOnImages(updatedTryOnImages)
                            setTryOnImagesBinary(updatedTryOnImagesBinary)
                          }}
                        >
                          <IoCloseCircleSharp color="red" size={28} />{" "}
                        </span>
                      </h4>
                      <div className="relative shadow-md">
                        <img
                          className="object-contain rounded-b-lg h-40"
                          // width={200}
                          src={item}
                          alt=''
                        />
                      </div>
                    </div>
                  ))}

                  {
                    uploadedTryOnImages?.map(item => (
                      <div className="selectedImage bg-gray-200 h-44 rounded-lg flex flex-col items-center justify-between">
                        <h4 className="text-center flex gap-4 items-center justify-between">{item?.color}
                        </h4>
                        <div className="relative shadow-md p-1">
                          <img
                            className="object-contain rounded-b-lg h-36"

                            src={item?.image}
                            alt=""
                          />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}