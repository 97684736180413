import { useState, useEffect, useRef } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import Pagination from "@mui/material/Pagination";
import { saveAs } from "file-saver";
import Skeleton from "@mui/material/Skeleton";

import {
  SET_INITIAL_STATE_AR,
  SET_HISTORY_DATA,
  SET_UPDATE_STATE_AR,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_HISTORY_DATA_FOR_SIDEBAR,
} from "../../../redux/reducerSlice/worldTrackSlice";

import { SET_AUTH } from "../../../redux/reducerSlice/authSlice";

import InputField from "components/fields/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { MdDelete, MdOpenInFull, MdOutlineClear, MdOutlineQrCodeScanner } from "react-icons/md";
import { setViewCreated } from "../../../redux/reducerSlice/valueSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "components/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaPlus, FaRegEye } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
// import { TextField } from "@mui/material";
import { FaPlusCircle } from "react-icons/fa";

const style = {
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  transform: "translate(-50%, -50%)",
  borderRadius: "0.5rem",
  outline: "none",
};

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const WorldTracking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth.auth.user._id);
  const allowedExperiencesCount = useSelector(
    (state) => state.auth.auth.user?.allowedExperiencesCount
  );
  const userToken = useSelector((state) => state.auth.auth.token);

  const user = useSelector((state) => state.auth.auth.user);
  const auth = useSelector((state) => state.auth.auth);

  const historyData = useSelector((state) => state.WorldTrack.historyData);
  console.log('historyData: ', historyData);
  const currentPage = useSelector((state) => state.WorldTrack.currentPageHistory);
  const totalPages = useSelector((state) => state.WorldTrack.totalPagesHistory);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [openDelete, setOpenDelete] = useState(null);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const handleDeleteClose = () => setOpenDelete(null);

  const forSideBar = async () => {
    try {
      const response = await instance.get(
        `api/WTracking/WorldTrackingList_history/${userid}?page=${currentPage}&limit=5000&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA_FOR_SIDEBAR(response?.data?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  const getHistoryData = async () => {
    if (!historyData) {
      setLoading(true);
    }
    
    console.log('itemsPerPage: ', itemsPerPage);

    try {
      const response = await instance.get(
        `api/WTracking/WorldTrackingList_history/${userid}?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA(response?.data?.data));
        dispatch(SET_HISTORY_TOTAL_PAGE(response?.data?.totalPages));

        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, [currentPage, searchParam, itemsPerPage]);

  const getUserDetails = async () => {
    try {
      const response = await instance.get(`/api/auth/getUser/${userid}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      const userData = {
        ...user,
        allowedExperiencesCount: response.data.data.allowedExperiencesCount,
      };

      console.log(userData, "userData");
      dispatch(SET_AUTH({ ...auth, user: userData }));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  useEffect(() => {
    getUserDetails();
    forSideBar();
  }, []);

  // const handleSearch = (e) => {
  //   const inputValue = e.target.value;
  //   setSearch(inputValue);
  //   if (!inputValue || inputValue.trim() === "") {
  //     setSearchParam("");
  //   } else {
  //     setSearchParam(inputValue.trim());
  //   }
  // };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleChange = (event, value) => {
    dispatch(SET_HISTORY_CURRENT_PAGE(value));
  };

  const handleChangeRows = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setSelectedValue(newItemsPerPage);

    const newPage = Math.min(
      currentPage,
      Math.ceil(historyData.length / newItemsPerPage)
    );
    dispatch(SET_HISTORY_CURRENT_PAGE(newPage));
  };

  return (
    <div className=" relative flex h-full w-full flex-col items-center gap-2 p-[30px] ">
   <div className="relative z-10 mt-1 flex w-full flex-wrap items-center justify-between p-4 bg-white shadow-sm rounded-lg">
  {/* Search Bar on the Left */}
  <input
    className="h-10 w-full md:w-[30%] rounded-lg border border-gray-300 p-2 text-sm focus:outline-none focus:ring-2 focus:ring-brand-main"
    placeholder="Search the Experience Name"
    value={search}
    onChange={handleSearch}
  />

  {/* Middle section with Create New Experience Button */}
  <div className="flex flex-col items-center justify-center space-y-2">
    {/* Create Experience Button */}
    <div
      className={`group relative flex h-[40px] w-[200px] items-center justify-center rounded-lg bg-brand-main text-white hover:bg-brand-dark transition duration-300 cursor-pointer shadow-md`}
      onClick={() => {
        if (
          historyData?.length <
          (allowedExperiencesCount ? allowedExperiencesCount : 1)
        ) {
          dispatch(SET_INITIAL_STATE_AR());
          handleOpen();
        } else {
          toast.error(
            `The user has exceeded the limit for creating experiences`
          );
        }
      }}
    >
      <FaPlus className="mr-2" size={16} />
      <span className="text-sm font-medium">Create Experience</span>
    </div>

    {/* Limit Information */}
    <span className="text-sm text-gray-600">
      Limit to Create Experience:{" "}
      <span className="font-semibold text-gray-800">
        {allowedExperiencesCount ?? `1`}
      </span>
    </span>
  </div>

  {/* Experience per page on the Right */}
  <div className="flex items-center gap-3">
    <p className="text-sm text-gray-600">Experience per page</p>
    <select
      className="w-20 h-10 border border-gray-300 rounded-lg bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-brand-main"
      value={itemsPerPage}
      onChange={handleChangeRows}
    >
      {[...Array(12).keys()].map((_, index) => (
        <>
          {index > 0 && (
            <option key={index} value={index * 5}>
              {index * 5}
            </option>
          )}
        </>
      ))}
    </select>
  </div>
</div>



      {/* Tooltip */}

      <div className="z-0 mt-3   w-full flex-col px-2 ">
        {loading ? (
          <div className="grid grid-cols-1 justify-center gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 ">
            {[...Array(6)].map((element) => (
              <Card
                xs={{ maxWidth: 450 }}
                className=" relative mx-auto h-full rounded-md bg-white md:mx-0 "
              >
                <div className="mt-2 flex flex-col gap-2 px-6 pb-2 pt-1 ">
                  <Skeleton
                    variant="rectangular"
                    height={150}
                    className="rounded-md"
                    animation="wave"
                  />

                  <Skeleton animation="wave" height={15} width="30%" />
                </div>

                <div className="  absolute right-8 top-3">
                  <Skeleton
                    variant="rectangular"
                    width={30}
                    height={30}
                    className="rounded-md  "
                    animation="wave"
                  />
                </div>
              </Card>
            ))}
          </div>
        ) : (
//           <div className="  grid grid-cols-1   ">
//          <div
//   className={`group relative mx-auto flex h-[140px] w-full max-w-[100%] flex-col items-center justify-center ${
//     historyData?.length >=
//     (allowedExperiencesCount ? allowedExperiencesCount : 1)
//       ? "cursor-not-allowed opacity-50"
//       : "cursor-pointer hover:shadow-md transition-shadow duration-300"
//   } rounded-lg bg-white py-4 px-6 shadow-lg`}
//   onClick={() => {
//     if (
//       historyData?.length <
//       (allowedExperiencesCount ? allowedExperiencesCount : 1)
//     ) {
//       dispatch(SET_INITIAL_STATE_AR());
//       handleOpen();
//     } else {
//       toast.error(
//         `The user has exceeded the limit for creating experiences`
//       );
//     }
//   }}
// >
//   <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-gray-100">
//     <FaPlus size={30} className="text-brand-main" />
//   </div>
//   <p className="text-base font-medium mt-3 text-center text-gray-800">
//     Create New Experience
//   </p>
//   <span className="text-sm mt-1 text-center text-gray-600">
//     Limit:{" "}
//     <span className="font-semibold text-gray-800">
//       {allowedExperiencesCount ?? `1`}
//     </span>
//   </span>
// </div>



//             {/* {historyData?.length > 0 &&
//               historyData?.map((item) => {
//                 return (
//                   <div
//                     className="group relative mx-auto flex w-[100%]  max-w-[400px] cursor-pointer flex-col rounded-lg bg-white pb-1 shadow-lg  md:mx-0  "
//                     key={item.id}
//                   >
//                     <div className="flex items-center justify-center overflow-hidden rounded-md px-6  ">
//                       <img
//                         src={
//                           item.targetImage && item.targetImage.src
//                             ? item.targetImage.src
//                             : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
//                         }
//                         alt="hello"
//                         loading="lazy"
//                         className="mt-2 h-[150px]  rounded-md object-cover"
//                       />
//                     </div>

//                     <div className="mt-2 flex items-center justify-between px-7 ">
//                       <p className="text-left text-gray-900 ">
//                         {item?.worldTrackingName ?? item?.name}
//                       </p>
//                       <p
//                         className={`text-xs font-semibold ${
//                           item.status === "published"
//                             ? "text-green-600"
//                             : item.status === "in-progress"
//                             ? "text-yellow-400"
//                             : "text-green-600"
//                         }`}
//                       >
//                         {item?.status?.toUpperCase()}
//                       </p>
//                     </div>
//                     <div className="absolute inset-0 top-2 flex h-full w-full items-center justify-center  opacity-100 duration-200 ">
//                       <button
//                         className="open-preview rounded-lg border   border-gray-200 bg-gray-100 px-2 py-2  text-center text-sm text-[#222] hover:border-none hover:bg-[#3d2fa9]  hover:text-white"
//                         onClick={() => {
//                           dispatch(SET_UPDATE_STATE_AR(item));
//                           dispatch(setViewCreated(""));
//                           navigate(`/admin/world_experience/${item.name}`);
//                         }}
//                       >
//                         Open-Preview / Edit
//                       </button>
//                     </div>

//                     <div className="absolute right-8 top-2 ">
//                       <div className="hs-dropdown relative inline-flex">
//                         <Dropdown
//                           button={
//                             <button
//                               id="hs-dropdown-custom-icon-trigger"
//                               type="button"
//                               className="hs-dropdown-toggle dark:bg-slate-900 flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 bg-white text-sm font-semibold text-gray-800  shadow-xl hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
//                             >
//                               <BsThreeDotsVertical size={18} />
//                             </button>
//                           }
//                           children={
//                             <div className="flex w-[60px] items-center justify-center rounded-lg bg-[#FBF9F1] shadow-xl">
//                               <div className="flex flex-col gap-3 px-2 py-3">
//                                 <p
//                                   className="cursor-pointer text-sm text-gray-800 hover:text-[#76453B] "
//                                   onClick={() => setOpenDelete(item._id)}
//                                 >
//                                   Delete
//                                 </p>
//                               </div>
//                             </div>
//                           }
//                           classNames={"top-8 right-5  w-full"}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })} */}


//           </div>
<></>
          
        )}
      </div>
      {/* <button
            className="rounded-md bg-[#3d2fa9] w-full p-2 py-4 flex items-center gap-3 justify-center text-sm font-semibold text-[white]"
           
          >
           World Tracking Experiences list
          </button> */}
          {historyData?.length > 0 &&
    historyData.map((element, index) => {
  return (
    <div
      key={index}
      className="flex items-center justify-between w-full rounded-md border bg-white px-4 py-4 mb-2 shadow-sm"
    >
      {/* Name, Date, Status, Actions (Centered) */}
      <div className="flex items-center justify-between w-full gap-4 text-center">
        {/* Name */}
        <p className="text-sm font-semibold flex-1 text-center">{element?.name}</p>

        {/* Date */}
        <p className="text-xs text-gray-500 flex-1 text-center">
          {new Date(element?.createdAt).toLocaleDateString()}
        </p>

        {/* Status */}
        <span
          className={`inline-block px-2 py-1 text-xs font-bold rounded flex-1 text-center ${
            element.status === 'published'
              ? 'bg-green-200 text-green-800'
              : element.status === 'draft'
              ? 'bg-yellow-200 text-yellow-800'
              : 'bg-red-200 text-red-800'
          }`}
        >
          {element?.status}
        </span>

        {/* Actions */}
        <div className="flex items-center gap-4 justify-center flex-1">
          {/* Preview Button */}
          <button
            className="bg-[#3d2fa9] text-white text-xs py-1 px-3 rounded hover:bg-[#3d2fa9]"
            onClick={() => {
              dispatch(SET_UPDATE_STATE_AR(element));
              dispatch(setViewCreated(""));
              navigate(`/admin/world_experience/${element.name}`);
            }}
          >
            Edit 
          </button>

          {/* Delete Button */}
          <MdDelete
            size={18}
            className="cursor-pointer text-red-400"
            onClick={() => setOpenDelete(element._id)}
          />
        </div>
      </div>
    </div>
  );
})
}



      <div className="mt-2 px-10 md:px-20">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>

      <CreateExperience open={open} handleClose={handleClose} getHistoryData={getHistoryData} />
      <Delete
        _id={openDelete}
        handleClose={handleDeleteClose}
        getHistoryData={getHistoryData}
      />
    </div>
  );
};

const CreateExperience = ({ open, handleClose ,getHistoryData}) => {
  const [loading, setLoading] = useState(false);
  const [experienceAvailable, setExperienceAvailable] = useState(true);
  const [experienceName, setExperienceName] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.auth.auth.token);
  const arData = useSelector((state) => state.WorldTrack.data);

  const userid = useSelector((state) => state?.auth?.auth?.user?._id);
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);
  const resellerId = useSelector(
    (state) => state?.auth?.auth?.user?.resellerId
  );

  const dispatch = useDispatch();

  const createARExperience = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        `/api/WTracking/create_WorldTracking`,
        {
          ...arData,
          userId: userid,
          userName: userName,
          name: name,
          worldTrackingName: experienceName,
          ...(resellerId && { resellerId: resellerId }),
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        const _id = response.data.data.insertedId;


        dispatch(
          SET_UPDATE_STATE_AR({
            ...arData,
            name: name,
            userId: userid,
            worldTrackingName: experienceName,
            _id,
            ...(resellerId && { resellerId: resellerId }),
          })
        );

        dispatch(setViewCreated(""));
       
        getHistoryData()
        navigate(`/admin/world_experience/${name}`);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const checkExperienceExist = async (nameparam) => {
    try {
      const response = await instance.post(
        "/api/WTracking/check_WorldTracking_name",
        {
          name: nameparam,
          userName: userName,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      setExperienceAvailable(response.data.data);
    } catch (error) {
      return false;
    }
  };

  const validationSchema = Yup.object({
    experienceName: Yup.string()
      .min(4, "Experience must be at least 4 characters")
      .required("Experience Name is required")
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        "Valid characters:letters,numbers,and underscores. "
      )
      .test("Ok", "Experience already exists", async () => {
        return experienceAvailable;
      }),
  });
  const formik = useFormik({
    initialValues: {
      experienceName: experienceName,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      console.log(values, "values");
      await createARExperience();
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="mt-2  flex  w-full flex-col justify-center p-4 outline-none  ">
          <div className="flex w-full items-center justify-center gap-2">
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Experience Name*"
                placeholder="Enter Experience Name"
                id="experienceName"
                type="text"
                onChange={(event) => {
                  formik.setFieldValue(
                    "experienceName",
                    event.target.value.toLowerCase()
                  );

                  setExperienceName(event.target.value.toLowerCase());
                  let inputValue = event.target.value.toLowerCase();
                  let result = inputValue.replace(/\s+/g, "-");

                  setName(result);
                  checkExperienceExist(result);
                }}
                value={formik.values.experienceName}
                error={
                  formik.touched.experienceName && formik.errors.experienceName
                }
              />
            </div>
          </div>
          <div className="mt-2 text-center text-sm">
            <button
              onClick={formik.handleSubmit}
              type="submit"
              className={`rounded-lg border border-gray-200 ${
                !experienceAvailable ? "bg-gray-600" : "bg-[#3d2fa9]"
              }   p-2 text-sm font-normal text-white`}
              disabled={!experienceAvailable}
            >
              Create Experience
            </button>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h3 className=" text-lg font-bold ">Creating AR experience</h3>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};

const Delete = ({ _id, handleClose, getHistoryData }) => {
  console.log(_id);
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleDelete = async (_id) => {
    setLoading(true);
    console.log(_id);
    try {
      const response = await instance.delete(
        `api/WTracking/WorldTracking_delete/${_id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        setLoading(false);
        getHistoryData();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={_id}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDelete} className="rounded-lg">
        <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
          <p className="text-lg font-bold ">Immarsify</p>
          <span onClick={handleClose} className="cursor-pointer">
            <MdOutlineClear size={20} />
          </span>
        </div>
        <p className="text-md p-4">Are you sure, you want to delete ?</p>

        <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
          <button
            className="rounded-lg border bg-[#3d2fa9] px-4 py-2 text-white "
            onClick={() => handleDelete(_id)}
          >
            yes
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-2 text-gray-900 "
            onClick={handleClose}
          >
            No
          </button>
        </div>
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h1 className="font-bold">Delete AR Experience</h1>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};

export default WorldTracking;
