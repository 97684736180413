import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";

const RowsPerPageSelect = ({ onChange }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value, "thisis value");
    setRowsPerPage(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        Rows per page:
      </Typography>

      <FormControl
        size="small"
        sx={{
          minWidth: 80,
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "primary.main",
            },
          },
        }}
      >
        <Select
          value={rowsPerPage}
          onChange={handleChange}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
              },
            },
          }}
        >
          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((num) => (
            <MenuItem
              key={num}
              value={num}
              sx={{
                "&:hover": {
                  backgroundColor: "primary.lighter",
                },
                "&.Mui-selected": {
                  backgroundColor: "primary.light",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                },
              }}
            >
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RowsPerPageSelect;
