import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { instance } from "common/Instance";

import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import upload from "../../../../../assets/img/upload.png";

import imageCompression from "browser-image-compression";
import { MdOutlineClear } from "react-icons/md";

import {

  UPDATE_RESUME_FIELD_ITEM,
} from "../../../../../redux/reducerSlice/arSlice";
import { AWS_DATA_PREFIX } from "common/Instance";
import { AWS_STATIC_PREFIX } from "common/Instance";
import { Dialog, DialogContent, Modal, Slide } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const iconTemplate = [
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/snapchat.svg"}`,
    description: "Snapchat",
    id: 1,
    link: `${
      AWS_DATA_PREFIX + "website+data/3d_modified/snapchat_modified/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/linkedin.svg"}`,
    description: "Linkedin",
    id: 2,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/linkedin_modified2/scene.gltf"
    }`,
  },
  // {
  //   small: `${AWS_DATA_PREFIX + "3d+icons/caller.svg"} `,
  //   description: "Caller",
  //   id: 3,
  //   link: `${AWS_DATA_PREFIX + "website+data/models+(1)/telephone_receiver_modified2/scene.gltf"}`,
  // },
  // {
  //   small: `${AWS_DATA_PREFIX + "3d+icons/envelope_9187471%201.svg"}`,
  //   description: "Enelope",
  //   id: 4,
  //   link: `${AWS_DATA_PREFIX + "website+data/models+(1)/mail_modified2/scene.gltf"} `,
  // },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Facebook.svg"}`,
    description: "Facebook",
    id: 5,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/facebook_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/image%2016.svg"}`,
    description: "Email",
    id: 6,
    link: `${AWS_STATIC_PREFIX + "3dmodels/mail3DIcon.glb"}`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Instagram.svg"}`,
    description: "Instagram",
    id: 7,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/instagram_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Pinterest.svg"}`,
    description: "Pinterest",
    id: 8,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/pinterest_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/reddit.svg"}`,
    description: "Reddit",
    id: 9,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/reddit_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Twitter.svg"}`,
    description: "Twitter",
    id: 10,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/twitter_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/gmail.svg"}`,
    description: "Gmail",
    id: 11,
    link: `${AWS_DATA_PREFIX + "website+data/models+(1)/mail3DIcon.glb"}`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/WhatsApp.svg"}`,
    description: "Whatsapp",
    id: 12,
    link: `${AWS_STATIC_PREFIX + "3dmodels/whatsApp3DIcon.glb"}`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/www.svg"}`,
    description: "Website",
    id: 13,
    link: `${
      AWS_DATA_PREFIX +
      "website+data/models+(1)/globe_with_meridians_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Youtube.svg"}`,
    description: "Youtube",
    id: 14,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/youtube1_modified2/scene.gltf"
    }`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/location.png"}`,
    description: "Location",
    id: 16,
    link: `${
      AWS_DATA_PREFIX +
      "website+data/models+(1)/map_pointer_modified2/scene.gltf"
    }`,
  },
];

const AddProductIcons = ({
  open,
  handleClose,
  setFields,
  fields,
  IconIndex,
  resumeId,
}) => {
  const selectedIcons = useSelector((state) => state.ar.data.icons);

  const [tab, setTab] = useState("upload");

  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  const dispatch = useDispatch();

  useEffect(() => {
    setTab("upload");
  }, [open]);

  const handleImageClick = (icon) => {
    console.log("icon", icon);
    try {
      const updḁtedItems = [...fields];
      console.log(updḁtedItems, "updḁtedItems");
      if (icon.src) {
        updḁtedItems[IconIndex] = {
          ...updḁtedItems[IconIndex],
          icon: { ...icon, small: icon.src, description: icon?.description },
        };
      } else {
        updḁtedItems[IconIndex] = {
          ...updḁtedItems[IconIndex],
          icon: { src: icon?.small, name: icon?.description },
        };
      }
      console.log(updḁtedItems, "updḁtedItems");
      setFields(updḁtedItems);
      dispatch(
        UPDATE_RESUME_FIELD_ITEM({
          resumeId,
          id: IconIndex + 1,
          itemData: updḁtedItems[IconIndex],
        })
      );
      handleClose();
    } catch (error) {
      console.error("Error handling image click:", error);
    }
    // const isSelected = selectedIcons.some(
    //   (selectedIcon) => selectedIcon.src === icon.small
    // );
    // if (isSelected) {
    //   dispatch(
    //     REMOVE_ICONS({
    //       src: icon.small,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     UPDATE_ICONS({
    //       id: uuidv4(),
    //       type: "icons",
    //       src: icon?.link,
    //       preview: icon.small,
    //       position: { x: 0, y: 0, z: 0 },
    //       rotation: { x: 3, y: 0, z: 0 },
    //       scale: { x: 0.1, y: 0.1, z: 0.1 },
    //       isAnimation: false,
    //       iconName: icon.description ?? "",
    //       linkType: "RedirectLink",
    //       isLink: true,
    //       iconLink: "",
    //       animation: {
    //         duration: 0,
    //         delay: 0,
    //         direction: "topToBottom",
    //       },
    //       isvCard: false,
    //       vCard: "",
    //       vCardJson: {
    //         firstName: user?.fullName?.split(" ")[0] ?? "",
    //         lastName: user?.fullName?.split(" ")[1] ?? "",
    //         photo: user?.image ?? "",
    //         organization: "",
    //         email: user?.email || "",
    //         workPhone: user?.phoneNumber ?? "",
    //         title: "",
    //         url: "",
    //         note: "",
    //         cellPhone: user?.phoneNumber ?? "",
    //         socialUrls: {
    //           custom: "",
    //           facebook: "",
    //           linkedIn: "",
    //           twitter: "",
    //         },
    //         homeAddress: {
    //           street: "",
    //           city: "",
    //           state: "",
    //         },
    //         workAddress: {
    //           street: "",
    //           city: "",
    //           state: "",
    //         },
    //         workUrl: "",
    //       },
    //     })
    //   );
    // }
  };

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;
      console.log(files, "imageFiles");

      if (files) {
        const imageFile = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, or .png file");
          return;
        }

        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 25) {
          setLoading(false);
          toast.error("File size should be less than 25 MB");
          return;
        }

        const options = {
          maxSizeMB: 1  ,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedBlob = await imageCompression(imageFile, options);
        console.log(
          "compressedBlob instanceof Blob",
          compressedBlob instanceof Blob
        );
        console.log(
          `compressedBlob size ${compressedBlob.size / 1024 / 1024} MB`
        );

        // Convert Blob to File
        const compressedFile = new File([compressedBlob], imageFile.name, {
          type: imageFile.type,
          lastModified: Date.now(),
        });

        const formData = new FormData();
        formData.append("file", compressedFile);
        formData.append("assetType", "image");
        const fileName = compressedFile.name.split(".").slice(0, -1).join(".");
        console.log(fileName, "fileName");

        const response = await instance.post(
          `/api/ar/upload/${user.username}/${arData.name}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setLoading(false);

          console.log(response, "thisresponse ");

          //setting the icon in the fields of resume

          const updḁtedItems = [...fields];
          console.log(updḁtedItems, "updḁtedItems");
          if (response?.data?.url) {
            updḁtedItems[IconIndex] = {
              ...updḁtedItems[IconIndex],
              icon: { src: response?.data?.url, name: "custom icon" },
            };
          }
          console.log(updḁtedItems, "updḁtedItems");
          setFields(updḁtedItems);
          dispatch(
            UPDATE_RESUME_FIELD_ITEM({
              resumeId,
              id: IconIndex + 1,
              itemData: updḁtedItems[IconIndex],
            })
          );
          handleClose();

          toast.success("Icon is Uploaded Successfully");

          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Icon is Not uploaded");
      handleClose();
    }
  };

  return (
    <Dialog
      anchor="left"
      open={open}
      onClose={handleClose}
      className="relative"
    >
      <DialogContent>
        <Box
          sx={{ width: 500 }}
          role="presentation"
          className="relative h-screen overflow-hidden"
        >
          <div>
            <div className="relative -mt-4 mb-9  flex flex-col ">
              <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
                <h4 className=" text-lg font-semibold ">Icons</h4>
                <span className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></span>
              </div>

              <div className="mb-2 flex w-full items-center justify-between gap-1 px-1 ">
                <button
                  className={`w-full rounded-lg  ${
                    tab === "upload"
                      ? "bg-[#3d2fa9] text-white"
                      : "border-2  text-[#3d2fa9] "
                  }  px-2 py-3  `}
                  onClick={() => setTab("upload")}
                >
                  Upload Icon
                </button>

                <button
                  className={`w-full rounded-lg px-2 py-3  ${
                    tab === "browseIcon"
                      ? "bg-[#3d2fa9] text-white"
                      : "border-2  text-[#3d2fa9] "
                  }`}
                  onClick={() => setTab("browseIcon")}
                >
                  Browse Icon
                </button>
              </div>

              {tab === "browseIcon" ? (
                <div className="mt-2  grid max-h-[100vh]   grid-cols-1 justify-center gap-4 overflow-y-auto px-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                  {iconTemplate?.map((icon, index) => (
                    <div
                      onClick={() => handleImageClick(icon, index)}
                      key={icon.id}
                      className={`flex h-24  cursor-pointer  overflow-hidden  hover:scale-105`}
                    >
                      <img
                        src={icon.small}
                        alt={icon.description}
                        className="flex h-24 w-full object-contain shadow-lg"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <div className=" mb-4 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                    <img
                      src={upload}
                      alt="select "
                      className="h-[40%] w-[40%] rounded-lg"
                    />

                    <h1 className="pb-2 text-center text-2xl font-semibold text-gray-800">
                      Upload Icon
                    </h1>
                  </div>

                  <div className="px-4">
                    <label
                      htmlFor="fileInput"
                      className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                    >
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/png, image/jpeg, image/jpg, image/svg"
                        className="hidden"
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                      />

                      <div className="flex flex-col items-center justify-center">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white "
                        >
                          Browse
                        </button>
                        <p className="mt-4 text-lg font-semibold text-gray-900">
                          Supported Format :- JPEG, JPG, PNG, SVG
                        </p>

                        <p className="text-md my-2 text-gray-900">
                          file limit size upto 25mb
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-4">
              <h1 className="font-bold">Uploading Icon</h1>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductIcons;
