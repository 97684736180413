import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

const ProtectedRoute = ({ element }) => {
  console.log(element, "this is element");
  const isAuthenticated = useSelector((state) => state?.auth?.auth?.token);
  const userType = useSelector((state) => state?.auth?.auth?.user?.type);
  const subType = useSelector((state) => state?.auth?.auth?.user?.subType);
  const isTryon = useSelector((state) => state?.auth?.auth?.user?.isTryon);
  const subscriptionType = useSelector(
    (state) => state?.auth?.auth?.user?.subscriptionType
  );
  const isAuthSubscription = useSelector(
    (state) => state?.auth?.auth?.user?.subscriptionsExpired
  );

  console.log("userType",userType);
  const resellerRoutes = [
    "/admin/dashboard",
    "/admin/users-list",
    "/admin/enquiry",
    "/admin/settings",
    "/admin/commission",
    "/admin/subreseller-list",
    "/admin/profile",
    "/admin/refer",
    "/admin/form",
    "/admin/cardform",
    "/admin/invoice",
    "/admin/invoice/create",
    "/admin/invoice/view",
    "/admin/invoice/edit",
    "/admin/invoice/record",
    "/admin/payment",
    "/admin/payment/view",
    "/admin/payment/edit",
    "/admin/recyclebin",
 
  ];

  const subResellerRoutes = [
    "/admin/dashboard",
    "/admin/users-list",
    "/admin/enquiry",
    "/admin/settings",
    "/admin/profile",
    "/admin/invoice",
    "/admin/invoice/create",
    "/admin/invoice/view",
    "/admin/invoice/edit",
    "/admin/invoice/record",
    "/admin/payment",
    "/admin/payment/view",
    "/admin/payment/edit",
   
  ];

  const userRouteProhibited = [
    "/admin/users-list",
    "/admin/subreseller-list",
    "/admin/refer",
    "/admin/commission",
    "/admin/invoice",
    "/admin/invoice/create",
    "/admin/invoice/view",
    "/admin/invoice/edit",
    "/admin/invoice/record",
    "/admin/payment",
    "/admin/payment/view",
    "/admin/payment/edit",
    "/admin/products",
    "/admin/orders",
    
  ];

  const planRouteProhibited = ["/admin/branding", "/admin/multiscene"];

  const AuthSubscription = ["/admin/upgrade_plan"];

  const location = useLocation();
  if (isAuthenticated) {
    if (userType === "reseller") {
      if (subType === "subReseller") {
        if (subResellerRoutes.includes(location.pathname)) {
          return element;
        } else {
          return <Navigate to="/admin/dashboard" />;
        }
      } else {
        if (resellerRoutes.includes(location.pathname)) {
          return element;
        } else {
          return <Navigate to="/admin/dashboard" />;
        }
      }
    } else if (userType === "user") {
      if (moment(isAuthSubscription).unix() > moment().unix()) {

        if (isTryon) {
          if (
            location.pathname === "/admin/dashboard" ||
            location.pathname === "/admin/products" ||
            location.pathname === "/admin/profile" ||
            location.pathname === "/admin/orders"
          ) {
            return element;
          } else {
            return <Navigate to="/admin/dashboard" />;
          }
        }

        if (!userRouteProhibited.includes(location.pathname)) {
          if (
            subscriptionType === "Silver" &&
            location.pathname === "/admin/dashboard"
          ) {
            return <Navigate to="/admin/template" />;
          }

          if (subscriptionType !== "Platinum") {
            if (!planRouteProhibited.includes(location.pathname)) {
              return element;
            } else {
              return <Navigate to="/admin/template" />;
            }
          }

          return element;
        } else {
          return <Navigate to="/admin/template" />;
        }
      } else {
        if (AuthSubscription.includes(location.pathname)) {
          return element;
        } else {
          return <Navigate to="/admin/upgrade_plan" />;
        }
      }
    } else {
      return element;
    }
  } else {
    return <Navigate to="/auth/sign-in" />;
  }
};

export default ProtectedRoute;
